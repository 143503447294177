import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from '../../controllers/store';
import { setCard, setDeliveryDate, toggleApprove, submit, loadCards, load } from './actions';
import FieldSelect from '../../components/form/field-select';
import { CmsComponent } from 'crownpeak-dxm-react-sdk';
import { Link } from 'react-router-dom';
import UserContext from '../../providers/user-provider';

export class RegisterSearchController extends CmsComponent {

    registerCtaText: any
    searchKeyword: any;
    constructor(props) {
        super(props);

        this.registerCtaText = props && props.data ? props.data.registerCtaText : null;
        this.searchKeyword = props && props.data ? props.data.search : null;
    }

    render() {
        return <RegisterSearch registerCtaText={this.registerCtaText} searchKeyword={this.props.data.search} />
    }
}
export class RegisterFormController extends CmsComponent {


    registerErrorImg: any;
    registerErrorImgAlt: any;
    registerErrorText: any;

    firstCheckboxLabel: any;
    secondCheckboxLabel: any;
    thirdCheckboxLabel: any;
    fourthCheckboxLabel: any;
    submitBtnText: any;
    dateFieldLabel: any;
    requiredFieldError: any;
    selectFieldLabel: any;
    textFieldLabel: any;
    formErrorImg: any;
    formErrorImgAlt: any;
    formErrorText: any;

    successAlertImg: any;
    successAlertImgAlt: any;
    successAlertText: any;

    contactCtaLink: any;
    contactCtaText: any;
    contactLabel: any;

    constructor(props) {
        super(props);
        this.cmsDisableDragDrop = true;
        this.registerErrorImg = props && props.registerError ? props.registerError.registerErrorImg : null;
        this.registerErrorImgAlt = props && props.registerError ? props.registerError.registerErrorImgAlt : null;
        this.registerErrorText = props && props.registerError ? props.registerError.registerErrorText : null;

        this.firstCheckboxLabel = props && props.formApprove ? props.formApprove.firstCheckboxLabel : null;
        this.secondCheckboxLabel = props && props.formApprove ? props.formApprove.secondCheckboxLabel : null;
        this.thirdCheckboxLabel = props && props.formApprove ? props.formApprove.thirdCheckboxLabel : null;
        this.fourthCheckboxLabel = props && props.formApprove ? props.formApprove.fourthCheckboxLabel : null;
        this.submitBtnText = props && props.formApprove ? props.formApprove.submitBtnText : null;
        this.dateFieldLabel = props && props.formApprove ? props.formApprove.dateFieldLabel : null;
        this.requiredFieldError = props && props.formApprove ? props.formApprove.requiredFieldError : null;
        this.selectFieldLabel = props && props.formApprove ? props.formApprove.selectFieldLabel : null;
        this.textFieldLabel = props && props.formApprove ? props.formApprove.textFieldLabel : null;
        this.formErrorImg = props && props.formApprove ? props.formApprove.formErrorImg : null;
        this.formErrorImgAlt = props && props.formApprove ? props.formApprove.formErrorImgAlt : null;
        this.formErrorText = props && props.formApprove ? props.formApprove.formErrorText : null;

        this.successAlertImg = props && props.successAlert ? props.successAlert.successAlertImg : null;
        this.successAlertImgAlt = props && props.successAlert ? props.successAlert.successAlertImgAlt : null;
        this.successAlertText = props && props.successAlert ? props.successAlert.successAlertText : null;

        this.contactCtaLink = props && props.contactData ? props.contactData.contactCtaLink : null;
        this.contactCtaText = props && props.contactData ? props.contactData.contactCtaText : null;
        this.contactLabel = props && props.contactData ? props.contactData.contactLabel : null;

    }

    render() {

        return (
            <React.Fragment>
                <RegisterError registerErrorImg={this.registerErrorImg} registerErrorImgAlt={this.registerErrorImgAlt} registerErrorText={this.registerErrorText} />
                <form>
                    <RegisterApprove
                        firstCheckboxLabel={this.firstCheckboxLabel}
                        secondCheckboxLabel={this.secondCheckboxLabel}
                        thirdCheckboxLabel={this.thirdCheckboxLabel}
                        fourthCheckboxLabel={this.fourthCheckboxLabel}
                        submitBtnText={this.submitBtnText}
                        dateFieldLabel={this.dateFieldLabel}
                        requiredFieldError={this.requiredFieldError}
                        selectFieldLabel={this.selectFieldLabel}
                        textFieldLabel={this.textFieldLabel}
                        formErrorImg={this.formErrorImg}
                        formErrorImgAlt={this.formErrorImgAlt}
                        formErrorText={this.formErrorText}
                    />
                </form>

                <RegisterSuccess successAlertImg={this.successAlertImg} successAlertImgAlt={this.successAlertImgAlt} successAlertText={this.successAlertText} />

                <RegisterContact contactCtaLink={this.contactCtaLink} contactCtaText={this.contactCtaText} contactLabel={this.contactLabel} />

            </React.Fragment>
        )

    }
}

export const RegisterSearch = ({ registerCtaText, searchKeyword }) => {
    const dispatch = useDispatch();
    const { user } = useContext(UserContext);
    const callLoadCards = (s: string): void => {
        dispatch(loadCards(user.accessToken, s, window.localStorage.lang));
    };

    return (
        <div className="basic-btn">
            <div className="btn-component-wrapper">
                <button type='button' className="btn-component" onClick={(): void => callLoadCards(searchKeyword)}>
                    {registerCtaText}
                </button>
            </div>
        </div>
    )
}

export const RegisterError = ({ registerErrorImg, registerErrorImgAlt, registerErrorText }) => {
    const dispatch = useDispatch();
    const { noCards, lang, error } = useSelector(
        (state: RootState) => ({
            noCards: state.register.noCards,
            lang: state.global.lang,
            error: state.register.error
        }),
        shallowEqual
    );
    useEffect(() => {
        dispatch(load());
    }, []);
    const callSetCard = (cId: string): void => {
        dispatch(setCard(cId));
    };
    return (
        noCards ? <div className="alert-wrapper register-alert-wrapper error-alert">
            <div>
                <img src={registerErrorImg} alt={registerErrorImgAlt} />
            </div>
            <p dangerouslySetInnerHTML={{ __html: error ? error : registerErrorText } } ></p>
        </div> : null
    )
}

export const RegisterApprove = ({ dateFieldLabel,
    firstCheckboxLabel,
    secondCheckboxLabel,
    thirdCheckboxLabel,
    fourthCheckboxLabel,
    submitBtnText,
    requiredFieldError,
    selectFieldLabel,
    textFieldLabel,
    formErrorImg,
    formErrorImgAlt,
    formErrorText }) => {
    const { user } = useContext(UserContext);
    const dispatch = useDispatch();
    const { cards, cardId, userApproved0, userApproved1, userApproved2, userApproved3, deliveryDate, error, saving, formDone, footer, noCards } = useSelector(
        (state: RootState) => ({
            cards: state.register.cards,
            userApproved0: state.register.userApproved0,
            userApproved1: state.register.userApproved1,
            userApproved2: state.register.userApproved2,
            userApproved3: state.register.userApproved3,
            deliveryDate: state.register.deliveryDate,
            cardId: state.register.cardId,
            error: state.register.error,
            saving: state.register.saving,
            formDone: state.register.formDone,
            footer: state.global.footer,
            noCards: state.register.noCards
        }),
        shallowEqual
    );

    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    var today = mm + '/' + dd + '/' + yyyy;

    const [state, setState] = useState({

        fields: {},
        errors: {
            checkbox0: '',
            checkbox1: '',
            checkbox2: '',
            checkbox3: '',
            date: ''
        }
    });
    const [dateValue, setDateValue] = useState('');
    const [formValid, setFormValid] = useState(true);

    function handleValidation() {
        let fields = state.fields;
        let errors = {
            checkbox0: '',
            checkbox1: '',
            checkbox2: '',
            checkbox3: '',
            date: ''
        };
        let formIsValid = true;

        //Checkboxes
        if (!fields["checkbox0"]) {
            formIsValid = false;
            errors["checkbox0"] = requiredFieldError.value;
        }

        if (!fields["checkbox1"]) {
            formIsValid = false;
            errors["checkbox1"] = requiredFieldError.value;
        }
        if (!fields["checkbox2"]) {
            formIsValid = false;
            errors["checkbox2"] = requiredFieldError.value;
        }
        if (!fields["checkbox3"]) {
            formIsValid = false;
            errors["checkbox3"] = requiredFieldError.value;
        }

        //Date
        if (!fields["date"]) {
            formIsValid = false;
            errors["date"] = requiredFieldError.value;
        }


        setState({
            ...state,
            errors: errors
        });
        return formIsValid;
    }


    function handleChange(e, field) {
        if (e.target.name === 'fielddate') {
            const year = parseInt(e.target.value.split('-')[0]);
            if (year > 9999) {
                e.target.value = dateValue;
            } else {
                setDateValue(e.target.value);
            }
        }
        let fields = state.fields;
        fields[field] = e.target.value;

        setState({
            ...state,
            fields
        });
    }
    const callSetCard = (e, cId: string, fieldName): void => {
        dispatch(setCard(cId));
    };
    const callSetDeliveryDate = (e): void => {
        dispatch(setDeliveryDate(e.target.value));
    };
    const callToggleApprove = (field: any): void => {
        dispatch(toggleApprove(field))
    };

    const callSubmit = (event, bearerToken: any): void => {
        event.preventDefault();
        if (handleValidation()) {
            setFormValid(true);
            dispatch(submit(bearerToken))
        } else {
            setFormValid(false);
        }

    };



    const selectedCard = cards.filter(c => c.id === cardId)[0];
    if (!cards || cards.length === 0) {
        return null;
    }
    return (
        !formDone ? <React.Fragment>
            {error || !formValid ? <div className="alert-wrapper register-alert-wrapper error-alert">
                <div>
                    <img src={formErrorImg} alt={formErrorImgAlt} />
                </div>
                <p dangerouslySetInnerHTML={{ __html: formErrorText }}></p>
            </div> : null}
            <div className="field field-select">
                <label htmlFor="fieldselect">{selectFieldLabel}</label>
                <FieldSelect
                    defaultText={cards[0].name}
                    optionsList={cards}
                    onChange={callSetCard}
                />
            </div>
            <div className="field field-text">
                <label htmlFor="fieldtext">{textFieldLabel}</label>
                {selectedCard ? <input type="text" name="fieldtext" disabled value={selectedCard.dealer} /> : null}
            </div>
            <div className="field field-date">
                <label htmlFor="fielddate">{dateFieldLabel}
                    {error ? <span className='field-error'>{error.message}</span> : null}
                    {state.errors.date ? <span className='field-error'>{state.errors.date}</span> : null}
                </label>
                <input type="date" id='dateInput' name="fielddate" required value={deliveryDate || today || ''} onChange={(e) => { handleChange(e, "date"); callSetDeliveryDate(e); }} />
            </div>
            <div className="field field-checkbox">
                <label>
                    {state.errors.checkbox0 ? <span className='field-error'>{state.errors.checkbox0}</span> : null}
                    <span dangerouslySetInnerHTML={{ __html: firstCheckboxLabel }}></span>
                    <input type="checkbox" onChange={(e) => { callToggleApprove('userApproved0'); handleChange(e, "checkbox0") }} defaultChecked={userApproved0} />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div className="field field-checkbox">
                <label>
                    {state.errors.checkbox1 ? <span className='field-error'>{state.errors.checkbox1}</span> : null}
                    <span dangerouslySetInnerHTML={{ __html: secondCheckboxLabel }}></span>
                    <input type="checkbox" onChange={(e) => { callToggleApprove('userApproved1'); handleChange(e, "checkbox1") }} defaultChecked={userApproved1} />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div className="field field-checkbox">
                <label>
                    {state.errors.checkbox2 ? <span className='field-error'>{state.errors.checkbox2}</span> : null}
                    <span dangerouslySetInnerHTML={{ __html: thirdCheckboxLabel }}></span>
                    <input type="checkbox" onChange={(e) => { callToggleApprove('userApproved2'); handleChange(e, "checkbox2") }} defaultChecked={userApproved2} />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div className="field field-checkbox">
                <label>
                    {state.errors.checkbox3 ? <span className='field-error'>{state.errors.checkbox3}</span> : null}
                    <span dangerouslySetInnerHTML={{ __html: fourthCheckboxLabel }}></span>
                    <input type="checkbox" onChange={(e) => { callToggleApprove('userApproved3'); handleChange(e, "checkbox3") }} defaultChecked={userApproved3} />
                    <span className="checkmark"></span>
                </label>
            </div>
            <div className="field field-submit">
                <button type="submit" name="fieldsubmit" onClick={(e) => callSubmit(e, user.accessToken)} disabled={saving || cardId === '0'}>
                    {saving ? <div className='loader btn-loader'></div> : submitBtnText}
                </button>
            </div>
        </React.Fragment> : null
    )
}



export const RegisterSuccess = ({ successAlertImg, successAlertImgAlt, successAlertText }) => {
    const { formDone } = useSelector(
        (state: RootState) => ({
            formDone: state.register.formDone,
        }),
        shallowEqual
    );

    return (
        formDone ? <div className="alert-wrapper register-alert-wrapper">
            <div>
                <img src={successAlertImg} alt={successAlertImgAlt} />
            </div>
            <p dangerouslySetInnerHTML={{ __html: successAlertText }}></p>
        </div> : null
    )
}


export const RegisterContact = ({ contactCtaLink, contactCtaText, contactLabel }) => {
    return (
        <div className="alert-wrapper contact-alert-wrapper">
            <div className='grey-btn'>
                <div className="btn-component-wrapper">
                    <Link to={contactCtaLink.value} className="btn-component">
                        {contactCtaText}
                    </Link>
                </div>
            </div>

            <p>{contactLabel}</p>
        </div>
    )
}


