/* eslint-disable import/no-anonymous-default-export */
import * as ActionTypes from "./actions";

export interface MachineCardRegister {
  id: string;
  name: string;
  dealer: string;
  serialNumber: string;
  materialNumber: string;
}

export interface RegisterState {
  loading: boolean;
  cards: MachineCardRegister[];
  deliveryDate: string | null;
  userApproved0: boolean;
  userApproved1: boolean;
  userApproved2: boolean;
  userApproved3: boolean;
  cardId: string | null;
  materialNumber: string | null;
  serialNumber: string | null;
  saving: boolean;
  error: any;
  formDone: boolean;
  noCards: boolean;
  file: File | null;
}

export type RegisterAction =
  | { type: typeof ActionTypes.LOAD_CARDS }
  | { type: typeof ActionTypes.NO_CARDS_FOUND, cards: MachineCardRegister[]; }
  | {
      type: typeof ActionTypes.LOAD_CARDS_SUCCESS;
      cards: MachineCardRegister[];
    }
  | { type: typeof ActionTypes.LOAD_CARDS_FAILED; err: Error }
  | { type: typeof ActionTypes.REGISTER_DELIVERY_DATE_UPDATED; value: string }
  | { type: typeof ActionTypes.REGISTER_APPROVE_TOGGLED; field: string }
  | { type: typeof ActionTypes.REGISTER_CARD_SET; id: string }
  | { type: typeof ActionTypes.REGISTER_FILE_SET; file: File }
  | { type: typeof ActionTypes.REGISTER_INIT }
  | { type: typeof ActionTypes.REGISTER_SAVING }
  | { type: typeof ActionTypes.REGISTER_COMPLETED }
  | { type: typeof ActionTypes.REGISTER_FAILED; err: {} };

const createInitialState = (): RegisterState => ({
  loading: false,
  cards: [],
  deliveryDate: null,
  userApproved0: false,
  userApproved1: false,
  userApproved2: false,
  userApproved3: false,
  cardId: null,
  serialNumber: null,
  materialNumber: null,
  saving: false,
  error: null,
  formDone: false,
  noCards: false,
  file: null,
});

export default function (
  state: any = createInitialState(),
  action: RegisterAction
): RegisterState {
  switch (action.type) {
    case ActionTypes.LOAD_CARDS: {
      return { ...state, loading: true, cards: [] };
    }
    case ActionTypes.NO_CARDS_FOUND: {
      return { ...state, loading: false, noCards: true, cards: [] };
    }
    case ActionTypes.LOAD_CARDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        cards: action.cards,
        noCards: false,
        cardId: action.cards.length === 1 ? action.cards[0].id : "0",
        materialNumber:
          action.cards.length === 1 ? action.cards[0].materialNumber : "0",
        serialNumber:
          action.cards.length === 1 ? action.cards[0].serialNumber : "0",
      };
    }
    case ActionTypes.LOAD_CARDS_FAILED: {
      return { ...state, loading: false, noCards: true, cards: [], error: action.err };
    }
  
    case ActionTypes.REGISTER_DELIVERY_DATE_UPDATED: {
      return { ...state, deliveryDate: action.value };
    }
    case ActionTypes.REGISTER_APPROVE_TOGGLED: {
      const approved = state[action.field];
      return { ...state, [action.field]: !approved };
    }
    case ActionTypes.REGISTER_CARD_SET: {
      return { ...state, cardId: action.id };
    }
    case ActionTypes.REGISTER_FILE_SET: {
      return { ...state, file: action.file };
    }
    case ActionTypes.REGISTER_INIT: {
      return { ...createInitialState() };
    }
    case ActionTypes.REGISTER_SAVING: {
      return { ...state, saving: true, error: null };
    }
    case ActionTypes.REGISTER_COMPLETED: {
      return { ...state, saving: false, formDone: true };
    }
    case ActionTypes.REGISTER_FAILED: {
      return { ...state, saving: false, error: action.err };
    }
    default: {
      return state;
    }
  }
}
