import Request from 'superagent/lib/client';
import { saveAs } from 'file-saver';
import nocache from 'superagent-no-cache';
import config from '../configuration';

class AbstractService {
  baseUrl: string;
  acceptContentType: string;
  bearerToken?: string;

  constructor(
    baseUrl: string,
    acceptContentType: string,
    bearerToken?: string
  ) {
    this.baseUrl = baseUrl;
    this.acceptContentType = acceptContentType;
    this.bearerToken = bearerToken;
  }

  get(url, params, options, data) {
    return this.httpRequest(url, 'get', params, data, options, undefined);
  }

  post(url, params, data, options) {
    return this.httpRequest(url, 'post', params, data, options, undefined);
  }

  postfile(url, params, data, file) {
    return this.httpRequest(url, 'postFile', params, data, null, file);
  }

  postForm(url, params, data, options) {
    return this.httpRequest(url, 'postForm', params, data, options, undefined);
  }

  put(url, params, data) {
    return this.httpRequest(url, 'put', params, data, undefined, undefined);
  }

  putNoResponse(url, params, data) {
    return this.httpRequest(
      url,
      'put',
      params,
      data,
      {
        parseRespAsJson: false
      },
      undefined
    );
  }

  postNoResponse(url, params, data) {
    return this.httpRequest(
      url,
      'post',
      params,
      data,
      {
        parseRespAsJson: false
      },
      undefined
    );
  }

  postReturnFile(url, params, data, filename) {
    return this.httpRequest(
      url,
      'post',
      params,
      data,
      {
        parseRespAsJson: false,
        parseFile: true,
        filename
      },
      undefined
    );
  }

  delete(url, params) {
    return this.httpRequest(
      url,
      'del',
      params,
      null,
      {
        parseRespAsJson: false
      },
      undefined
    );
  }

  async httpRequest(url, httpMethod, params, data, options, file) {
    options = options || {};
    if (typeof options.parseRespAsJson === 'undefined') {
      options.parseRespAsJson = true;
    }
    const absoluteUrl = this.baseUrl + url;
    let req;
    if (httpMethod === 'postForm') {
      req = Request.post(absoluteUrl).timeout(config.runtime.apiTimeoutMs);
      if (data) {
        Object.keys(data).forEach((key) => {
          req.send(key + '=' + data[key]);
        });
      }
    } else if (httpMethod === 'postFile') {
      req = Request.post(absoluteUrl)
        .timeout(config.runtime.apiTimeoutMs)
        .set('Accept', this.acceptContentType);
      if (file) {
        req.attach('files', file, file.name);
      }
    } else {
      req = Request[httpMethod](absoluteUrl)
        .timeout(config.runtime.apiTimeoutMs)
        .set('Accept', this.acceptContentType);
    }
    if (data) {
      if (httpMethod === 'postFile') {
        req.field('Content-Type', 'multipart/form-data');
        Object.keys(data).forEach((k) => {
          req.field(k, data[k]);
        });
      } else {
        req.send(data);
      }
    }
    if (params) {
      req.query(params);
    }
    const requestInfo = {
      url: absoluteUrl,
      method: httpMethod
    };

    if (this.bearerToken) {
      req.set('Authorization', `Bearer ${this.bearerToken}`);
    } else {
      // redirect to login
    }

    return await this.sendRequest(req, options, requestInfo);
  }

  sendRequest(req, options, requestInfo) {
    let respParser = options.parseRespAsJson ? (e) => JSON.parse(e) : (e) => e;

    if (options.parseFile) {
      req.responseType('blob');
    }

    req.use(nocache); //turn off request caching, needed for at least IE

    const promise = new Promise<void>(function (resolve, reject) {
      req.end(function (err, res) {
        if (err) {
          if (err.status && err.status === 404) {
            reject();
          } else if (err.status && err.status === 400) {
            reject();
          } else if (!err.status && process.env.NODE_ENV !== 'development') {
            alert('Something went wrong while updating, the object you are changing might be in use and cant be deleted or altered: '+err);
            reject( AbstractService.resolveResponseError(err, options, requestInfo) );
          } else if (err.crossDomain || err.status === 0) {
            // Handle CORS error or network error
            reject("A network error occurred. Please check your connection or contact support.");
          } else {
            reject( "Oops, something went wrong" );
          }       

        } else if (res.error) {
          reject(
            AbstractService.resolveResponseError(
              { status: res.status, response: res },
              options,
              requestInfo
            )
          );
        } else if (options.parseFile) {
          //Handle file upload
          saveAs(res.body, options.filename);
          resolve();
        } else {
          try {
              //Handle successful response
              resolve(respParser(res.text));
          } catch (e) {
            reject(AbstractService.resolveParseError(e, res, requestInfo));
          }
        }
      });
    });
    return promise;
  }

  isSuccess(statusCode) {
    return Math.floor(statusCode / 100) === 2;
  }

  isError(statusCode) {
    return !this.isSuccess(statusCode);
  }

  static resolveResponseError(err, options, requestInfo): Error {
    let status = err.status || 0;
    let details = err;
    let error = AbstractService.serviceError(
      '<p>Service is temporary not working</p><p>'+
      requestInfo.method.toUpperCase()+
        ' to ' +
        requestInfo.url +
        ' failed with status ' +
        status +'</p><p>'+details+'</p>',
      {
        status: status,
        url: requestInfo.url,
        method: requestInfo.method,
        serviceError: true
      }
    );
    if (!err.status || !err.response) {
      // Timeout, network error or other general errors
      return error;
    }

    (error as any).data.text = err.response.text;
    (error as any).data.type = err.response.type;
    if (err.response.text.length === 0) {
      return error;
    }
    const errorObj = JSON.parse(err.response.text);
    if (errorObj.error_description) {
      (error as any).data.caption = errorObj.error_description;
      (error as any).data.error = true;
    }
    if (errorObj.ModelState) {
      if (errorObj.ModelState['']) {
        const errors = errorObj.ModelState[''];
        if (errors.length > 0) {
          (error as any).data.caption = errors[0];
          (error as any).data.error = true;
        }
      }
    }
    if (options.parseRespAsJson && err.response.type.endsWith('json')) {
      try {
        (error as any).data.serverError = JSON.parse((error as any).data.text);
      } catch (e: any) {
        e.data = (error as any).data;
        error = e;
        (error as any).parseError = true;
      }
    }
    return error;
  }

  static resolveParseError(err, res, requestInfo) {
    err.data = {
      status: res.status,
      url: requestInfo.url,
      method: requestInfo.method,
      serviceError: true,
      text: res.text,
      type: res.type,
      parseError: true
    };
    return err;
  }

  static serviceError(message, data) {
    const error = new Error(message);
    (error as any).data = data;
    return error;
  }
}
export default AbstractService;
